import React from 'react';
import { Helmet } from 'react-helmet';


const Scripts = () => {
  return (
    <Helmet>
      <script src="assets/js/popper.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>
      <script src="assets/js/owl.js"></script>
      <script src="assets/js/wow.js"></script>
      <script src="assets/js/validation.js"></script>
      <script src="assets/js/jquery.fancybox.js"></script>
      <script src="assets/js/appear.js"></script>
      <script src="assets/js/scrollbar.js"></script>
      <script src="assets/js/nav-tool.js"></script>
      <script src="assets/js/pagenav.js"></script>
      <script src="assets/js/jquery.paroller.min.js"></script>
      <script src="assets/js/jquery.nice-select.min.js"></script>
      <script src="assets/js/script.js"></script>
      <script>
        {`
          !function(){
            var e,t,n,a;
            window.MyAliceWebChat||(
              (t=document.createElement("div")).id="myAliceWebChat",
              (n=document.createElement("script")).type="text/javascript",
              n.async=!0,
              n.src="https://widget.myalice.ai/index.js",
              (a=(e=document.body.getElementsByTagName("script"))[e.length-1]).parentNode.insertBefore(n,a),
              a.parentNode.insertBefore(t,a),
              n.addEventListener("load",(function(){
                MyAliceWebChat.init({ 
                  selector: "myAliceWebChat", 
                  number: "91998061999", 
                  message: "Welome to the Renovation Hub", 
                  color: "#25D366", 
                  channel: "wa", 
                  boxShadow: "medium", 
                  text: "Message Us", 
                  theme: "light", 
                  position: "right", 
                  mb: "20px", 
                  mx: "20px", 
                  radius: "20px" 
                })
              }))
            )
          }();
        `}
        
      </script>
    </Helmet>
  );
}

export default Scripts;
