import React from 'react';
import Scripts from './components/Scripts';
import Footer from './components/Footer';
import Home from './pages/Home';

function App() {


  return (
    <div className="boxed_wrapper">

      <div className="preloader"></div>

      <div className="xs-sidebar-group info-group info-sidebar">
        <div className="xs-overlay xs-bg-black"></div>
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div className="widget-heading">
              <a href="/" className="close-side-widget">X</a>
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-info-contents">
                <div className="content-inner">
                  <div className="logo">
                    <a href="index-2.html"><img src="assets/images/Main_logo.png" alt="" /></a>
                  </div>
                  <div className="content-box">
                    <h4>About Us</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut
                      labore et magna aliqua. Ut enim ad minim veniam laboris.</p>
                  </div>
                  <div className="form-inner">
                    <h4>Get a free quote</h4>
                    <form action="https://azim.commonsupport.com/Zixxo/index.html" method="post">
                      <div className="form-group">
                        <input type="text" name="name" placeholder="Name" required="" />
                      </div>
                      <div className="form-group">
                        <input type="email" name="email" placeholder="Email" required="" />
                      </div>
                      <div className="form-group">
                        <textarea name="message" placeholder="Message..."></textarea>
                      </div>
                      <div className="form-group message-btn">
                        <button type="submit" className="theme-btn-one">Submit Now</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-header style-one">
        {/* header-lower */}
        <div className="header-lower">
          <div className="outer-box">
            <div className="logo-box">
              <figure className="logo"><a href="index-2.html"><img style={{ height: "80px" }} src="assets/images/Main_logo.png" alt="" /></a>
              </figure>
            </div>
            <div className="menu-area">
              {/*Mobile Navigation Toggler*/}
              <div className="mobile-nav-toggler">
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
                <i className="icon-bar"></i>
              </div>
              <nav className="main-menu navbar-expand-md navbar-light">
                <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                  <ul className="navigation scroll-nav clearfix">
                    <li className="current"><a href="#banner">Home</a>
                    </li>
                    <li><a href="#about">About Us</a></li>
                    <li><a href="#feature">Services</a></li>
                    <li><a href="#gallery">Portfolio</a></li>
                    <li><a href="#testimonial">Testimonials</a></li>
                  </ul>
                </div>
              </nav>

            </div>
            <div className="nav-right-content">
              <div className="btn-box"><a href="index-2.html">Get a quote<i
                className="far fa-long-arrow-right"></i></a></div>

            </div>
          </div>
        </div>
        {/*sticky Header*/}
        <div className="sticky-header">
          <div className="outer-box" style={{ backgroundColor: "#0C0A00" }}>
            <div className="logo-box">
              <figure className="logo"><a href="index-2.html"><img style={{ height: "80px" }} src="assets/images/Main_logo.png" alt="" /></a>
              </figure>
            </div>
            <div className="menu-area">
              <nav className="main-menu clearfix">
                {/*Keep This Empty / Menu will come through Javascript*/}
              </nav>
              <nav className="main-menu navbar-expand-md navbar-light">
                <div className="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                  <ul className="navigation scroll-nav clearfix">
                    <li className="current"><a href="#banner">Home</a>
                    </li>
                    <li><a href="#about">About Us</a></li>
                    <li><a href="#feature">Services</a></li>
                    <li><a href="#gallery">Portfolio</a></li>
                    <li><a href="#testimonial">Testimonials</a></li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="nav-right-content">
              <div className="btn-box"><a href="index-2.html">Get a quote<i
                className="far fa-long-arrow-right"></i></a></div>

            </div>
          </div>
        </div>
      </div>



      <Home />

      <Footer />


      <Scripts />

    </div>
  );
}

export default App;

