import React from 'react';

function Home() {
    return (
        <>
    
            <section id='banner' className="banner-section style-one" style={{ backgroundColor: "#1a1a1a" }}>
                <div className="banner-carousel owl-theme owl-carousel owl-nav-none owl-dots-none">
                    <div className="slide-item" style={{ backgroundColor: "#1a1a1a" }}>
                        <div className="auto-container">
                            <div className="row align-items-center clearfix">
                                <div className="col-lg-5 col-md-12 col-sm-12 content-column">
                                    <div className="content-box">
                                        <h1>Transforming Spaces, Inspiring Lives</h1>
                                        <p>Your Renovation Journey Starts Here!</p>
                                        <div className="btn-box">
                                            <a href="product.html" className="banner-btn-one">Get Quote now<i
                                                className="far fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12 col-sm-12 image-column">
                                    <div className="image-box">
                                        <figure className="image image-1">
                                            <img src="assets/images/banner/banner-image-1.jpg" alt="" />
                                        </figure>
                                        <figure className="image image-2"><img src="assets/images/banner/banner-image-2.jpg"
                                            alt="" /></figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='about' className="about-section" style={{ backgroundColor: "#1a1a1a" }}>
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                            <div className="image_block_1">
                                <div className="image-box">
                                    <figure className="image image-1 paroller"><img src="assets/images/resource/about-1.jpg"
                                        alt="" /></figure>
                                    <figure className="image image-2 paroller-2"><img src="assets/images/resource/about-2.jpg"
                                        alt="" /></figure>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                            <div className="content_block_1">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <p style={{ color: "#fffdfb" }}>About Company</p>
                                        <h2>Explore Modern Tiles Stone & Toilet Services Agency. We Are Very Popular For
                                            This Type Of Service Providing</h2>
                                    </div>
                                    <div className="text" >
                                        <p style={{ color: "#fffdfb" }} >Sed ut perspiciatis unde omnis iste natus error sit voluptat em accusantium
                                            doloremque laudantium, totam rem apriam eaque ipsa quae ab illo inventore
                                            veritatis et quas</p>
                                        <p style={{ color: "#fffdfb" }}>accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo
                                            inventore veritatis et quasi architecto</p>
                                    </div>
                                    <div className="btn-box">
                                        <a href="about.html" className="theme-btn-one">Read more<i
                                            className="far fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='feature' className="feature-section bg-color-1" style={{ backgroundColor: "#1a1a1a" }}>

                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="col-lg-5 col-md-12 col-sm-12 content-column">
                            <div className="content_block_2">
                                <div className="content-box">
                                    <div className="sec-title">
                                        <p style={{ color: "#fffdfb" }}>What We Offer</p>
                                        <h2>Discover Our Best Offer To Made Your Nice House</h2>
                                    </div>
                                    <div className="text" style={{ color: "#fffdfb" }}>
                                        <p style={{ color: "#fffdfb" }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                                            doloremque laudantium, totam rem apriam eaque ipsa quae ab illo inventore</p>
                                    </div>
                                    <div className="btn-box">
                                        <a href="index-3.html" className="theme-btn-one">Read more<i
                                            className="far fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 inner-column">
                            <div className="feature-inner centred">
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                                        <div className="feature-block-one">
                                            <div className="inner-box">
                                                <div className="icon-box"><i className="flaticon-tile-2"></i></div>
                                                <h3>Modern Tiels</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                                        <div className="feature-block-one">
                                            <div className="inner-box">
                                                <div className="icon-box"><i className="flaticon-toilet-2"></i></div>
                                                <h3>Modern Toilet</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                                        <div className="feature-block-one">
                                            <div className="inner-box">
                                                <div className="icon-box"><i className="flaticon-stones"></i></div>
                                                <h3>Modern Stone</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 feature-block">
                                        <div className="feature-block-one">
                                            <div className="inner-box">
                                                <div className="icon-box"><i className="flaticon-cogwheels"></i></div>
                                                <h3>Work Toolkits</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta-section centred" style={{ backgroundImage: `url(assets/images/background/cta-1.jpg)` }}>
                <div className="auto-container">
                    <div className="inner-box">
                        <div className="video-btn">
                            <a href="https://www.youtube.com/watch?v=nfP5N9Yc72A&amp;t=28s" className="lightbox-image video-btn"
                                data-caption="">
                                <i className="fas fa-play"></i>
                                <span className="border-animation border-1"></span>
                                <span className="border-animation border-2"></span>
                                <span className="border-animation border-3"></span>
                            </a>
                        </div>
                        <p>Get More Update</p>
                        <h2>Need Any Helps For Modern <br />Tiles & Toilet Join Us</h2>
                        <div className="btn-box">
                            <a href="contact.html" className="theme-btn-one">Join with us<i
                                className="far fa-long-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </section>


            <section id='gallery' className="gallery-section" style={{ backgroundColor: "#1a1a1a" }}>
                <div className="text" style={{ textAlign: "center" }}>
                    <p style={{ color: "#ae9c9c", fontWeight: "700", fontSize: 'large' }}>PORTFOLIO LIST</p>
                    <h1 style={{ fontWeight: "600", marginBottom: "3rem", color: "#fffdfb" }}>Recent Completed Activity</h1>
                </div>
                <div className="auto-container">
                    <div className="row align-items-center clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 big-column">
                            <div className="inner-column mr-20 clearfix">
                                <div className="gallery-block-one pull-right">
                                    <div className="inner-box">
                                        <figure className="image-box"><img src="assets/images/gallery/gallery-1.jpg" alt="" />
                                        </figure>
                                        <div className="lower-content">
                                            <div className="icon-box"><a href="assets/images/gallery/gallery-1.jpg"
                                                className="lightbox-image" data-fancybox="gallery"><i
                                                    className="fal fa-plus"></i></a></div>
                                            <h4><a href="index-2.html">Modern Toilet</a></h4>
                                            <p>Best House Instruments</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="gallery-block-one pull-right">
                                    <div className="inner-box">
                                        <figure className="image-box"><img src="assets/images/gallery/gallery-2.jpg" alt="" />
                                        </figure>
                                        <div className="lower-content">
                                            <div className="icon-box"><a href="assets/images/gallery/gallery-2.jpg"
                                                className="lightbox-image" data-fancybox="gallery"><i
                                                    className="fal fa-plus"></i></a></div>
                                            <h4><a href="index-2.html">Modern Toilet</a></h4>
                                            <p>Best House Instruments</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 big-column">
                            <div className="inner-column clearfix">
                                <div className="gallery-block-one pull-left">
                                    <div className="inner-box">
                                        <figure className="image-box"><img src="assets/images/gallery/gallery-3.jpg" alt="" />
                                        </figure>
                                        <div className="lower-content">
                                            <div className="icon-box"><a href="assets/images/gallery/gallery-3.jpg"
                                                className="lightbox-image" data-fancybox="gallery"><i
                                                    className="fal fa-plus"></i></a></div>
                                            <h4><a href="index-2.html">Modern Toilet</a></h4>
                                            <p>Best House Instruments</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="gallery-block-one pull-left">
                                    <div className="inner-box">
                                        <figure className="image-box"><img src="assets/images/gallery/gallery-4.jpg" alt="" />
                                        </figure>
                                        <div className="lower-content">
                                            <div className="icon-box"><a href="assets/images/gallery/gallery-4.jpg"
                                                className="lightbox-image" data-fancybox="gallery"><i
                                                    className="fal fa-plus"></i></a></div>
                                            <h4><a href="index-2.html">Modern Toilet</a></h4>
                                            <p>Best House Instruments</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='testimonial' className="testimonial-section bg-color-1" style={{ backgroundColor: "#1a1a1a" }}>
                <div className="image-layer">
                    <figure className="image-1"><img src="assets/images/icons/comment-1.png" alt="" /></figure>
                    <figure className="image-2"><img src="assets/images/shape/shape-1.png" alt="" /></figure>
                </div>
                <div className="auto-container">
                    <div className="sec-title centred">
                        <p style={{ color: "#fffdfb" }}>Clients Feedback</p>
                        <h2>What Our Customer Say’s <br />About Our Product</h2>
                    </div>
                    <div className="single-item-carousel owl-carousel owl-theme owl-dots-none owl-nav-none">
                        <div className="testimonial-block">
                            <div className="inner-box">
                                <div className="text">
                                    <p style={{ color: "#fffdfb" }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                        laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis quasi
                                        archite beatae vitae dicta sunt explicabo</p>
                                </div>
                                <h3 style={{ color: "#fffdfb" }}>Lester M. Norwood<span>Web Designer</span></h3>
                                <figure className="author-thumb"><img src="assets/images/resource/testimonial-1.png" alt="" /></figure>
                            </div>
                        </div>
                        <div className="testimonial-block">
                            <div className="inner-box">
                                <div className="text">
                                    <p style={{ color: "#fffdfb" }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                        laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis quasi
                                        archite beatae vitae dicta sunt explicabo</p>
                                </div>
                                <h3 style={{ color: "#fffdfb" }}>Lester M. Norwood<span>Web Designer</span></h3>
                                <figure className="author-thumb"><img src="assets/images/resource/testimonial-1.png" alt="" /></figure>
                            </div>
                        </div>
                        <div className="testimonial-block">
                            <div className="inner-box">
                                <div className="text">
                                    <p style={{ color: "#fffdfb" }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                        laudantium totam rem aperiam eaque ipsa quae ab illo inventore veritatis quasi
                                        archite beatae vitae dicta sunt explicabo</p>
                                </div>
                                <h3 style={{ color: "#fffdfb" }}>Lester M. Norwood<span>Web Designer</span></h3>
                                <figure className="author-thumb"><img src="assets/images/resource/testimonial-1.png" alt="" /></figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
