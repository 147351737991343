import React from 'react';


const Footer = () => {
  return (
    <footer className="main-footer" style={{ backgroundColor: "#0C0A00" }} >
      <div className="auto-container">
        <div className="logo-box centred">
          <figure className="footer-logo"><a href="index-2.html"><img style={{ height: "100px" }} src="assets/images/footer_logo.png"
            alt="" /></a></figure>
        </div>
        <div className="widget-section">
          <div className="row clearfix">
            <div className="col-lg-6 col-md-12 col-sm-12 footer-column">
              <div className="footer-widget about-widget">
                <div className="widget-title">
                  <h3>About Us</h3>
                </div>
                <div className="text">
                  <p>Sed ut perspici unde omnis iste natus error sit voluptatem accan tium remque
                    laudantium, totam rem aperiam, eaque ipsa ventore veritatis et quasi architecto
                    beatae vitae dicta explicabo.</p>
                </div>
                <form action="https://azim.commonsupport.com/Zixxo/contact.html" method="post"
                  className="subscribe-form">
                  <div className="form-group">
                    <input type="email" name="email" placeholder="Enter Your Email" required="" />
                    <button type="submit"><i className="far fa-arrow-right"></i></button>
                  </div>
                </form>
                <ul className="social-links clearfix">
                  <li><a href="index-2.html"><i className="fab fa-facebook-f"></i>Facebook</a></li>
                  <li><a href="index-2.html"><i className="fab fa-twitter"></i>Twitter</a></li>
                  <li><a href="index-2.html"><i className="fab fa-youtube"></i>Youtube</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 footer-column">
              <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 column">
                  <div className="footer-widget links-widget ml-70">
                    <div className="widget-title">
                      <h3>Quick Links</h3>
                    </div>
                    <div className="widget-content">
                      <ul className="links-list clearfix">
                        <li><a href="index-2.html">Company History</a></li>
                        <li><a href="index-2.html">Latest Services</a></li>
                        <li><a href="index-2.html">Meet The Team</a></li>
                        <li><a href="index-2.html">Recent News</a></li>
                        <li><a href="index-2.html">Success History</a></li>
                        <li><a href="index-2.html">Fun Fact</a></li>
                        <li><a href="index-2.html">Tiles Toilet Stones</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 column">
                  <div className="footer-widget contact-widget ml-40">
                    <div className="widget-title">
                      <h3>Contact Us</h3>
                    </div>
                    <div className="text">
                      <p>Sed ut perspici unde omnis iste natus error sit voluptatem accan tium
                        doloremque laudan</p>
                    </div>
                    <ul className="info-list clearfix">
                      <li><i className="far fa-phone"></i><a href="tel:01234567899">+0123 (456)
                        7899</a></li>
                      <li><i className="far fa-envelope-open"></i><a
                        href="mailto:support@example.com">support@example.com</a></li>
                      <li><i className="far fa-map-marker-alt"></i>205 Main Street, USA</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom centred">
          <p>&copy; 2020 <a href="index-2.html">Zixxo</a>. All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
